import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import parameters from 'parameters'
import queryString from 'query-string'
import ReactLoading from 'react-loading'
import {
  A10Component,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { A10Message } from '@gui-libraries/widgets'
import {
  AuthenticationService,
  DashboardService,
  OrganizationService,
  Utilities,
} from 'src/services/index'
import AppRoot from 'src/settings/appRoot'
import { ProductLogo } from 'src/components/shared/ProductLogo'
import { Footer } from 'src/components/shared/Footer'
import { NonLoggenInForm } from 'src/components/shared/NonLoggenInForm'
import { GoogleAuthLogin } from 'src/components/shared/GoogleAuthLogin'
import { UserRole } from 'src/constants/Data/UserRole'
import { IDefaultMethods } from 'src/containers/Controller'
import 'src/styles/login.less'
import MFARegistration from '../MFA/MFARegistration'
import SecondFactorAuthentication from '../MFA/MFATwoFactorAuthentication/SecondFactorAuthentication'
import {
  NOT_APPLICABLE,
  PENDING,
  QR_CODE_GENERATED,
  CONFIGURED,
} from 'src/constants/MFAStatus/MFAConstants'

export interface ILoginParams {
  provider: string
}

export interface ILoginProps
  extends IA10ContainerDefaultProps,
    RouteComponentProps<ILoginParams> {
  defaultMethods: IDefaultMethods
}
export interface ILoginState {
  userObj: any
  provider: string
  loggedIn: boolean
  clientId: string
  shouldRender: boolean
  googleAuthPayload: any
  isAuthTypeOauth: boolean
  isSuperAdmin: boolean
  mfaStatus: string
  mfaEnabled: boolean
  headers: IObject
  enforcedDate: string
  skipTenantCheck: boolean
}

class Login extends A10Component<ILoginProps, ILoginState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  AppRoot = new AppRoot()
  // Instantiating instances of imported services
  AuthenticationService = new AuthenticationService()
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  Utilities = new Utilities()
  UserRole = new UserRole()

  availableTenants: any = []
  readTenants: any = []
  roleList: any = []
  selectedTenants: string = ''
  appToBeLoaded: string = 'Home'
  isProviderAdmin: boolean = false
  googleAuthFallback: boolean = false

  sessionResponse: IObject = []
  userResponse: IObject = []
  authenticationHeader: IObject = {}
  mfaStatus: string = ''
  // Defining state.
  state = {
    mfaEnabled: false,
    registrationDone: false,
    isSuperAdmin: false,

    userObj: [
      {
        name: 'userId',
        label: 'Username',
        placeholder: 'User ID',
        type: 'text',
        required: true,
        value: '',
        id: 1,
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        type: 'password',
        required: true,
        value: '',
        id: 2,
      },
    ],
    provider: this.props.match.params.provider,
    loggedIn: false,
    clientId: '',
    shouldRender: false,
    isAuthTypeOauth: false,
    googleAuthPayload: {},
    mfaStatus: '',
    headers: {},
    enforcedDate: '',
    skipTenantCheck: true,
  }

  referralToken: string = ''
  constructor(props: ILoginProps) {
    super(props)
    this.getAuthType()
    const strLocation = this.props.location.search
    const queryParams = queryString.parse(strLocation)
    const params = new URLSearchParams(strLocation)
    this.referralToken = params.get('token')
    this.googleAuthFallback =
      queryParams.google_auth_fallback === '1' ? true : false
  }

  componentDidMount() {
    const {
      storage: { get, set, remove, clear },
    } = this.context

    if (get.LASTALERT) {
      this.Utilities.showMessage('SESSION_INVALID', 0, true)
      remove.LASTALERT()
    }
    clear()
    set.PROVIDER(this.props.match.params.provider)
  }

  getAllTenants = async () => {
    const {
      storage: { get, set },
    } = this.context

    const { provider: strProvider } = this.state
    const headers = {
      Authorization: `Session ${get.USER_SESSION_ID}`,
      provider: strProvider,
    }

    const tenantResponse = this.DashboardService.getTenants(
      headers,
      null,
      strProvider,
    )
    await tenantResponse
      .then((response: IObject) => {
        const tenantList: IObject = []
        let tenantDetails =
          response && response.data && response.data['tenant-list']
            ? response.data['tenant-list']
            : []
        tenantDetails = tenantDetails.filter((tenant: IObject) => {
          return tenant.type !== 'built-in'
        })
        tenantDetails.map((obj: any) => {
          obj = this.Utilities.minimizeAppSvcLPinfoFromTenant(obj)
          tenantList.push(obj.name)
        })

        if (tenantList.length > 0) {
          set.CURRENT_TENANT(tenantDetails[0])
          set.AVAILABLETENANTS(tenantList)
          this.AppRoot.setRootScopeElement('availableTenants', tenantList)
          this.appToBeLoaded = 'Home/tenant/' + tenantList[0]
          // this.setState({ loggedIn: true })
        } else {
          this.setState({ skipTenantCheck: false })
          const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
          this.Utilities.showMessage(alertMsg, 0, 0)
        }
      })
      .catch((error: any) => {
        this.setState({ skipTenantCheck: false })
        const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
        this.Utilities.showMessage(alertMsg, 0, 0)
      })
  }

  getTenantsFromAccessGroup = async (headers: any, roleName: string[]) => {
    const {
      storage: { get, set },
    } = this.context

    const roleResponse = await this.OrganizationService.getRbacAccessGroups(
      headers,
      null,
      [this.state.provider],
    )
    if (roleResponse) {
      const accessGroups =
        roleResponse && roleResponse.data ? roleResponse.data : []
      let tenantList: string[] = []
      let allTenants = false
    
      accessGroups &&
        accessGroups.map((accessGroup: IObject) => {
          if (roleName.includes(accessGroup.name)) {
            // fix for HC-9756 - issue when there are multiple tenants present and access is given to only specific tenant(s) in the system.
            // modified code to get the list of tenants present on access-group object instead from role object which comes from standard roles available.
            tenantList = accessGroup?.resourceList?.tenant?.reduce((result:string[], item:IObject) => {
              const tenantName = item?.name
              if (tenantName !== 'pso' && tenantName !== "*") {
                result.push(tenantName)
              }
              return result;
            }, []) ?? []
            
            if (accessGroup?.resourceList?.tenant?.[0]?.name === '*') {
              allTenants = true
            }
          }
        })

      if (tenantList.length > 0) {
        const curTenant = {
          name: tenantList[0],
        } as Storage.ITenant
        set.CURRENT_TENANT(curTenant)
        set.AVAILABLETENANTS(tenantList)
        this.AppRoot.setRootScopeElement('availableTenants', tenantList)
        this.appToBeLoaded = 'Home/tenant/' + tenantList[0]
        this.setState({ loggedIn: true })
      } else if (allTenants) {
        // To get all tenants - use summary API
        await this.getAllTenants()
      } else {
        this.setState({ skipTenantCheck: false })
        const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
        this.Utilities.showMessage(alertMsg, 0, 0)
      }
    } else {
      this.setState({ skipTenantCheck: false })
      console.log('Rbac roles load failed')
      const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
      this.Utilities.showMessage(alertMsg, 0, 0)
    }
  }
  getAuthType = () => {
    const authHeader = { Accept: 'text/plain' }
    this.AuthenticationService.getClientId(authHeader, null, [
      this.state.provider,
    ])
      .then((resp: any) => {
        if (resp.data !== '') {
          this.setState({
            shouldRender: true,
            clientId: resp.data,
            isAuthTypeOauth: true,
          })
        } else {
          this.setState({
            shouldRender: true,
          })
        }
      })
      .catch((error: any) => {
        if (error.response) {
          this.setState({
            shouldRender: true,
          })
        } else {
          this.setState({
            shouldRender: true,
          })
          const apiURL = parameters.BASE_URL
          this.Utilities.showMessage(
            <>
              Certificate Error. <br />
              Please accept the certificate for{' '}
              <a target="_blank" href={apiURL}>
                url
              </a>
              , and try again. <br />
              Ignore any error on accepting the certificate.
            </>,
            0,
            0,
            '',
            10,
          )
        }
      })
  }

  changeHandler = (e: any) => {
    const objIndex = this.state.userObj.findIndex(ele => {
      return ele.name === e.target.name
    })
    const upadtedObject = [...this.state.userObj]
    upadtedObject[objIndex].value = e.target.value
    this.setState({
      userObj: upadtedObject,
    })
    e.target.value = this.state.userObj[objIndex].value
  }

  setGoogleAuthPayload = (googleAuthPayload: any) => {
    this.setState({ googleAuthPayload })
    this.loginHandler(null)
  }

  setHelpTexts = (headers: any) => {
    const {
      storage: { set },
    } = this.context

    let helpTextsReq = this.AuthenticationService.getHelpTexts(
      headers,
      null,
      null,
    )
    helpTextsReq
      .then((resp: any) => {
        let helpTextMap = resp.data.map
        set.HELP_TEXTS(helpTextMap)
        this.AppRoot.setRootScopeElement('helpTexts', resp.data.map)
      })
      .catch((statusCode: number) => {
        if (statusCode === 404) {
          this.AuthenticationService.createHelpTextRepo(
            null,
            {
              namespace: 'AppcitoHelpTexts',
              key: 'value',
            },
            null,
          )

          set.HELP_TEXTS({
            key: 'value',
          })
          this.AppRoot.setRootScopeElement('helpTexts', {
            key: 'value',
          })
        }
      })
  }

  // To get the list of Roles
  getRoleList = async (headers: any) => {
    const roleListResponse = this.OrganizationService.getRbacRoleList(
      headers,
      null,
      [this.state.provider],
    )
    await roleListResponse
      .then((resp: any) => {
        this.roleList = resp?.data ? resp.data : []
      })
      .catch((err: any) => {
        console.log('rbac role load failed')
        this.roleList = []
      })
  }

  setLocalStorage = (sessionResponse: IObject, userResponse: IObject) => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization:
        'Basic ' +
        new Buffer(
          this.state.userObj[0].value + ':' + this.state.userObj[1].value,
        ).toString('base64'),
      provider: this.state.provider,
    }
    const { showMessage } = this.Utilities
    const {
      storage: { get, set },
    } = this.context
    set.USER_SESSION_ID(sessionResponse.id)
    set.USER_ID(sessionResponse.userId)
    set.PROVIDER(sessionResponse.provider.name)
    set.PROVIDER_ID(sessionResponse.provider.uuid)
    if (sessionResponse.authenticationProvider) {
      this.AppRoot.setRootScopeElement(
        'sessionAuthType',
        sessionResponse.authenticationProvider.type,
      )
      set.USER_SESSION_AUTHTYPE(sessionResponse.authenticationProvider.type)
    }
    let targetAPIurl = '/providers/' + sessionResponse.provider.name
    let providerPath = sessionResponse.provider.name
    let i = 0
    let currentParent = sessionResponse.provider
    while (i === 0) {
      if (currentParent.parent) {
        targetAPIurl = '/providers/' + currentParent.parent.name + targetAPIurl
        providerPath = currentParent.parent.name + '/' + providerPath
        currentParent = currentParent.parent
      } else {
        i = 1
      }
    }
    set.TARGET_URL(targetAPIurl)
    set.PROVIDER_PATH(providerPath)
    let session = sessionResponse.id
    let userName =
      (userResponse['first-name'] !== undefined
        ? userResponse['first-name']
        : '') +
      ' ' +
      (userResponse['last-name'] !== undefined ? userResponse['last-name'] : '')
    set.USER_NAME(userName)
    set.ACTIVATION_TOKEN(userResponse['activation-token'])
    set.USER_EMAIL_ID(userResponse['email-id'])
    set.LOCAL_USER(userResponse['local-user'])
    set.ENCODED_SESSION_ID(`Session ${session}`)

    let noRole = true,
      superAdmin = false,
      providerAdmin = false,
      tenantAdmin = false,
      tenantRoleList: string[] = [],
      rolePriority = 999,
      highestRole = ''
    if (userResponse['roles'] && userResponse['roles'].length > 0) {
      set.LOGGED_IN(true)
      userResponse['roles'].map((roleObj: IObject) => {
        const roles = roleObj ? Object.keys(roleObj) : []
        if (
          roles[0] &&
          (roles[0] === 'superadmin' || roles[0] === 'superadmin-root')
        ) {
          noRole = false
          superAdmin = true
          this.AppRoot.setRootScopeElement('isSuperUser', true)
          set.IS_SUPER_USER(true)
          rolePriority = 0
        } else if (roles[0] && roleObj[roles[0]]) {
          // Get the role object to check for the rolePriority
          const role = this.roleList.find((obj: any) => {
            return obj.name === roleObj[roles[0]]
          })
          if (role && role.rolePriority < rolePriority) {
            rolePriority = role.rolePriority
            highestRole = roleObj[roles[0]]
          }
          // getting the list of tenant/app user groups (admins/operators)
          if (
            this.UserRole.RBAC_ROLE_TYPES.tenantadmin.includes(
              roleObj[roles[0]],
            )
          ) {
            tenantRoleList.push(roles[0])
          }
        }
      })
    }

    // setting up the login access as per the highest role for the logged-in user (other than the super-admin)

    if (noRole && highestRole !== '') {
      if (this.UserRole.RBAC_ROLE_TYPES.provideradmin.includes(highestRole)) {
        noRole = false
        providerAdmin = true
      } else if (
        this.UserRole.RBAC_ROLE_TYPES.tenantadmin.includes(highestRole)
      ) {
        noRole = false
        tenantAdmin = true
      }

      if (this.UserRole.RBAC_ROLE_TYPES.infraadmin.includes(highestRole)) {
        this.AppRoot.setRootScopeElement('isInfraUser', true)
        set.IS_INFRA_USER(true)
      }
      if (this.UserRole.RBAC_ROLE_TYPES.certadmin.includes(highestRole)) {
        this.AppRoot.setRootScopeElement('isCertUser', true)
        set.IS_CERT_USER(true)
      }
      if (this.UserRole.RBAC_ROLE_TYPES.partitionadmin.includes(highestRole)) {
        this.AppRoot.setRootScopeElement('isPartitionUser', true)
        set.IS_PARTITION_USER(true)
      }
      if (this.UserRole.RBAC_ROLE_TYPES.operators.includes(highestRole)) {
        this.AppRoot.setRootScopeElement('isOperatorUser', true)
        set.IS_OPERATOR_USER(true)
      }
    }

    if (noRole) {
      const alertMsg = `You don't have any access group associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
      showMessage(alertMsg, 0, 0)
    } else {
      if (superAdmin || providerAdmin) {
        set.ADMIN_LEVEL('provider')
        set.DRILL_LEVEL('provider')
        this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        set.JUST_LOGIN(true)
      } else {
        set.ADMIN_LEVEL('tenant')
        set.DRILL_LEVEL('tenant')
        this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')
      }
    }
    if (providerAdmin) {
      this.appToBeLoaded = 'Home'
    } else {
      if (this.state.mfaStatus === NOT_APPLICABLE || !this.state.mfaEnabled) {
        // NOT_APPLICABLE = 'NA'
        if (!superAdmin) {
          this.getTenantsFromAccessGroup(headers, tenantRoleList)
        }
      }
    }
  }

  async resetProviderMFA(userId: string, provider: string, sessionId: string) {
    const headers = {
      Authorization: 'Session ' + sessionId,
      provider: provider,
    }

    const payload = {
      userId: userId,
      uuidToken: this.referralToken,
    }

    let returnVal = false
    try {
      const { status: statusCode } = await this.OrganizationService.resetMFA(
        headers,
        payload,
        null,
      )
      if (statusCode === 200) {
        returnVal = true
      }
    } catch {
      returnVal = false
    }

    return returnVal
  }

  handleRecoverMFA = (userId: string, authorization: string) => {
    const payload = {
      userId: userId,
    }

    const headers = {
      Authorization: authorization,
    }

    const recoveryRequest = this.AuthenticationService.recoverMFASettings(
      headers,
      payload,
      null,
    )
    recoveryRequest
      .then((response: IObject) => {
        if (response.status === 200) {
          A10Message.success(`${response.data}. Redirecting to login page.`)
          const url = `/login/${this.state.provider}`
          setTimeout(function() {
            window.location.href = url
          }, 3000)
        } else {
          A10Message.error(
            'An error occurred while sending an email! Please try again.',
          )
        }
      })
      .catch(error => {
        A10Message.error(
          'An error occurred while sending an email! Please try again.',
          error,
        )
      })
  }

  loginHandler = (e: any) => {
    const {
      storage: { get, set },
    } = this.context

    const { showMessage } = this.Utilities
    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization:
        'Basic ' +
        new Buffer(
          this.state.userObj[0].value + ':' + this.state.userObj[1].value,
        ).toString('base64'),
      provider: this.state.provider,
      client: 'HC_UI',
    }

    let payload = {
      userId: this.state.userObj[0].value,
    }

    this.authenticationHeader.payload = payload

    if (this.state.isAuthTypeOauth && !this.googleAuthFallback) {
      const authHeaders: any = { ...headers }
      const googlePayload: any = this.state.googleAuthPayload

      delete authHeaders.Authorization
      authHeaders.token = googlePayload.access_token

      headers = authHeaders
      payload = googlePayload
    }

    //new session is getting created
    const loginRequest = this.AuthenticationService.createNewSession(
      headers,
      payload,
      null,
    )

    loginRequest
      .then(async (resp: IObject) => {
        this.setState({ skipTenantCheck: true })
        const response = resp.data
        let blnContinue = true
        if (this.referralToken) {
          const isSuccess = await this.resetProviderMFA(
            response['userId'],
            this.state.provider,
            response['id'],
          )
          if (isSuccess) {
            response['user']['mfa-status'] = PENDING // PENDING = 'PENDING'
          } else {
            A10Message.error('Invalid URL or Token Expired! Please try again')
            blnContinue = false
          }
        }

        if (blnContinue) {
          this.sessionResponse = response
          this.setState({
            mfaStatus: response.authenticationProvider['mfa-enabled']
              ? response.user['mfa-status']
              : 'NA',
            mfaEnabled: response.authenticationProvider['mfa-enabled'],
            enforcedDate: response.authenticationProvider['enforcement-date'],
          })

          set.USER_SESSION_ID(response.id)
          set.USER_ID(response.userId)
          set.PROVIDER(response.provider.name)
          set.PROVIDER_ID(response.provider.uuid)
          set.IS_MFA_ENABLE(response.authenticationProvider['mfa-enabled'])
          if (response.authenticationProvider) {
            this.AppRoot.setRootScopeElement(
              'sessionAuthType',
              response.authenticationProvider.type,
            )
          }
          let session = response.id
          let userid = response.userId
          headers.Authorization = 'Session ' + session
          document.cookie = `ENCODED_SESSION_ID=Session ${session}; path=/; expires=;`;
          document.cookie = `PROVIDER=${response.provider.name}; path=/; expires=;`,
          this.authenticationHeader.headers = headers
          await this.getRoleList(headers)
          this.setState({ headers: headers })
          try {
            const userDetails = await this.AuthenticationService.getAdminDetails(
              headers,
              null,
              [userid],
            )
            if (userDetails) {
              userDetails?.data?.roles?.map((roleObj: IObject) => {
                const roles = roleObj ? Object.values(roleObj) : []
                set.USER_ROLES(roles[0])
              })
              let userResponse = userDetails.data
              this.userResponse = userDetails.data
              this.AppRoot.setRootScopeElement(
                'localUser',
                userResponse['local-user'],
              )

              let noRole = true,
                isDeviceCommu = false,
                superAdmin = false,
                providerAdmin = false,
                tenantAdmin = false,
                tenantRoleList: string[] = [],
                rolePriority = 999,
                highestRole = ''
              if (userResponse['roles'] && userResponse['roles'].length > 0) {
                this.setHelpTexts(headers)
                userResponse['roles'].map((roleObj: IObject) => {
                  const roles = roleObj ? Object.keys(roleObj) : []
                  if (
                    roles[0] &&
                    (roles[0] === 'superadmin' ||
                      roles[0] === 'superadmin-root')
                  ) {
                    noRole = false
                    superAdmin = true
                    this.AppRoot.setRootScopeElement('isSuperUser', true)
                    set.IS_SUPER_USER(true)
                    rolePriority = 0
                  } else if (
                    roles[0] &&
                    roleObj[roles[0]] === 'hc_device_comm'
                  ) {
                    isDeviceCommu = true
                  } else if (roles[0] && roleObj[roles[0]]) {
                    // Get the role object to check for the rolePriority
                    const role = this.roleList.find((itemRole: IObject) => {
                      return itemRole.name === roleObj[roles[0]]
                    })
                    if (role && role.rolePriority < rolePriority) {
                      rolePriority = role.rolePriority
                      highestRole = roleObj[roles[0]]
                    }
                    // getting the list of tenant/app user groups (admins/operators)
                    if (
                      this.UserRole.RBAC_ROLE_TYPES.tenantadmin.includes(
                        roleObj[roles[0]],
                      )
                    ) {
                      tenantRoleList.push(roles[0])
                    }
                  }
                })
              }

              // setting up the login access as per the highest role for the logged-in user (other than the super-admin)
              if (noRole && highestRole !== '') {
                if (
                  this.UserRole.RBAC_ROLE_TYPES.provideradmin.includes(
                    highestRole,
                  )
                ) {
                  noRole = false
                  providerAdmin = true
                  if (highestRole === 'hc_provider_admin') {
                    this.isProviderAdmin = true
                  }
                } else if (
                  this.UserRole.RBAC_ROLE_TYPES.tenantadmin.includes(
                    highestRole,
                  )
                ) {
                  noRole = false
                  tenantAdmin = true
                }

                if (
                  this.UserRole.RBAC_ROLE_TYPES.infraadmin.includes(highestRole)
                ) {
                  this.AppRoot.setRootScopeElement('isInfraUser', true)
                }
                if (
                  this.UserRole.RBAC_ROLE_TYPES.certadmin.includes(highestRole)
                ) {
                  this.AppRoot.setRootScopeElement('isCertUser', true)
                }
                if (
                  this.UserRole.RBAC_ROLE_TYPES.partitionadmin.includes(
                    highestRole,
                  )
                ) {
                  this.AppRoot.setRootScopeElement('isPartitionUser', true)
                }
                if (
                  this.UserRole.RBAC_ROLE_TYPES.operators.includes(highestRole)
                ) {
                  this.AppRoot.setRootScopeElement('isOperatorUser', true)
                }
              }

              if (isDeviceCommu) {
                const deviceComm_AlertMsg = `Your account does not have privileges to access Harmony Controller portal. Please contact your system administrator for more details.`
                showMessage(deviceComm_AlertMsg, 0, 0)
              } else if (noRole) {
                const alertMsg = `You don't have any access group associated with provider ${get.PROVIDER}, Please contact your domain administrator for required access.`
                showMessage(alertMsg, 0, 0)
              } else {
                if (superAdmin || providerAdmin) {
                  this.AppRoot.setRootScopeElement(
                    'currentDrillLevel',
                    'provider',
                  )
                } else {
                  this.AppRoot.setRootScopeElement(
                    'currentDrillLevel',
                    'tenant',
                  )
                }

                if (superAdmin) {
                  set.SUPER_ADMIN_MODE(true)
                  this.appToBeLoaded = 'Providers'
                  this.setState({ isSuperAdmin: true, loggedIn: true })
                  this.setLocalStorage(this.sessionResponse, this.userResponse)
                } else if (providerAdmin) {
                  this.appToBeLoaded = 'Home'

                  if (
                    this.state.mfaStatus === NOT_APPLICABLE ||
                    !this.state.mfaEnabled
                  ) {
                    // NOT_APPLICABLE = 'NA;
                    this.setLocalStorage(
                      this.sessionResponse,
                      this.userResponse,
                    )
                  }
                } else {
                  if (
                    this.state.mfaStatus === NOT_APPLICABLE ||
                    !this.state.mfaEnabled
                  ) {
                    // NOT_APPLICABLE = 'NA;
                    this.setLocalStorage(
                      this.sessionResponse,
                      this.userResponse,
                    )
                  }
                  await this.getTenantsFromAccessGroup(headers, tenantRoleList)
                }

                if (this.state.skipTenantCheck) {
                  this.setState({ loggedIn: true, isSuperAdmin: false })
                }
              }
            }
          } catch (error) {
            console.error(error)
          }
        }
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            const message =
              error.response.data && typeof error.response.data === 'string'
                ? error.response.data
                : ''
            this.Utilities.showMessage(
              message || 'LOGIN_UNAUTHORISED',
              0,
              !message ? true : false,
            )
          } else {
            const message =
              error.response.data && error.response.data.message
                ? error.response.data.message
                : error.response.statusText
            this.Utilities.showMessage('LOGIN_FAILED', 0, true, message)
          }
        } else {
          const apiURL = parameters.BASE_URL
          showMessage(
            <>
              Certificate Error. <br />
              Please accept the certificate for{' '}
              <a target="_blank" href={apiURL}>
                url
              </a>
              , and try again. <br />
              Ignore any error on accepting the certificate.
            </>,
            0,
            0,
            '',
            10,
          )
        }
      })
  }

  setupLater = () => {
    this.setLocalStorage(this.sessionResponse, this.userResponse)
  }

  getTenantsFromRoles = (headers: any, roleList: string[]) => {
    const {
      storage: { get, set },
    } = this.context

    const roleResponse = this.OrganizationService.getRbacRoles(headers, null, [
      this.state.provider,
    ])
    roleResponse
      .then((resp: any) => {
        const roleListResp =
          resp && resp.data && resp.data['role-list']
            ? resp.data['role-list']
            : []
        const tenantList: any = []
        roleListResp &&
          roleListResp.map((role: any) => {
            if (roleList.indexOf(role.name) > -1) {
              role['url-list'] &&
                role['url-list'].map((urls: any) => {
                  if (
                    urls.url.indexOf(
                      `/provider/${this.state.provider}/tenant/`,
                    ) > -1
                  ) {
                    const tenant = urls.url
                      .replace(`/provider/${this.state.provider}/tenant/`, '')
                      .split('/')
                    if (tenant && tenant[0] !== '') {
                      tenantList.push(tenant[0])
                    }
                  }
                })
            }
          })

        if (tenantList.length > 0) {
          set.AVAILABLETENANTS(tenantList)
          this.AppRoot.setRootScopeElement('availableTenants', tenantList)
          this.appToBeLoaded = 'Home/tenant/' + tenantList[0]
          this.setState({ loggedIn: true })
        } else {
          this.setState({ skipTenantCheck: false })
          const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
          this.Utilities.showMessage(alertMsg, 0, 0)
        }
      })
      .catch((error: any) => {
        this.setState({ skipTenantCheck: false })
        console.log('Rbac roles load failed')
        const alertMsg = `You don't have any tenants associated with provider ${get.PROVIDER} , Please contact your domain administrator for required access.`
        this.Utilities.showMessage(alertMsg, 0, 0)
      })
  }

  loader = (): JSX.Element => {
    return (
      <div className="col-md-12 google-login-container">
        <div className="col-md-7 col-sm-8 centered login-form">
          <div className="form-group clearfix">
            <div className="col-md-12">
              <div className="loading-icon">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={70}
                  width={70}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderGoogleAuth = () => {
    return (
      <GoogleAuthLogin
        label="Login"
        submitLabel="Login"
        oauthClientID={this.state.clientId}
        setPayload={this.setGoogleAuthPayload}
      />
    )
  }

  renderDefaultForm = () => {
    return (
      <NonLoggenInForm
        content={this.state.userObj}
        label="Login"
        onSubmit={this.loginHandler}
        onChange={this.changeHandler}
        submitLabel="Login"
        showBack={false}
        showForgotPass={true}
        showCancel={false}
        showLoginOperConsole={true}
      />
    )
  }

  render() {
    const {
      storage: { get },
    } = this.context

    const userDetails = {
      userName: this.state.userObj[0].value,
      pwd: this.state.userObj[1].value,
      provider: get.PROVIDER,
    }
    const {
      isSuperAdmin,
      mfaStatus,
      enforcedDate,
      skipTenantCheck,
    } = this.state
    let uri = `/controller/${this.appToBeLoaded}`
    return !this.state.loggedIn ? (
      <div className="login-default">
        <div className="a10-logo-container">
          <div className="a10-logo" />
        </div>
        <div className="login-view">
          <ProductLogo />
          {this.state.shouldRender === true
            ? this.state.isAuthTypeOauth
              ? this.googleAuthFallback
                ? this.renderDefaultForm()
                : this.renderGoogleAuth()
              : this.renderDefaultForm()
            : this.loader()}
          <Footer pageType="login" />
        </div>
      </div>
    ) : isSuperAdmin && skipTenantCheck ? (
      <Redirect to={uri} />
    ) : mfaStatus === CONFIGURED && skipTenantCheck ? ( // CONFIGURED = 'CONFIGURED'
      <SecondFactorAuthentication
        userDetails={userDetails}
        sessionResponse={this.sessionResponse}
        userResponse={this.userResponse}
        authenticationHeader={this.authenticationHeader}
        recoverMFA={this.handleRecoverMFA}
        isProviderAdmin={this.isProviderAdmin}
        setLocalStorage={this.setLocalStorage}
        appToBeLoaded={uri}
      />
    ) : mfaStatus === PENDING ||
      (mfaStatus === QR_CODE_GENERATED && skipTenantCheck) ? ( // QR_CODE_GENERATED = 'QR CODE GENERATED'
      <MFARegistration
        userDetails={userDetails}
        sessionResponse={this.sessionResponse}
        userResponse={this.userResponse}
        authenticationHeader={this.authenticationHeader}
        setupLater={this.setupLater}
        enforcedDate={enforcedDate}
        recoverMFA={this.handleRecoverMFA}
        isProviderAdmin={this.isProviderAdmin}
        setLocalStorage={this.setLocalStorage}
        appToBeLoaded={uri}
      />
    ) : (
      <Redirect to={uri} />
    )
  }
}

export default Login
