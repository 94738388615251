import React from 'react'
import { A10Component, _ } from '@gui-libraries/framework'

import { Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import EulaActivation from '../EulaActivation'

import './styles/nonloggeninform.scss'
import 'src/styles/login.less'

export interface INonLoggenInFormProps {
  label: string
  content: any
  onChange: any
  onSubmit: any
  submitLabel: any
  showBack: boolean
  showForgotPass: boolean
  showCancel: boolean
  showLoginOperConsole?: boolean
  user: string
  token: string
}

export interface INonLoggenInFormState {
  showEulaActivation: boolean
}

class NonLoggenInForm extends A10Component<
  INonLoggenInFormProps,
  INonLoggenInFormState
> {
  Utilities = new Utilities()

  state = {
    showEulaActivation: false,
  }

  keyPressFun(event: any, passCheck: boolean) {
    if (passCheck && event.key === '?') {
      event.preventDefault()
      this.Utilities.showMessage(
        "Character '?' is not allowed in password field",
        0,
        false,
      )
    }
    if (event.key === 'Enter') {
      this.props.onSubmit()
    }
  }

  setEulaActivation = (show: boolean) => {
    this.setState({ showEulaActivation: show })
  }

  render() {
    const { showEulaActivation } = this.state
    const {
      get: {
        PROVIDER: provider = 'root',
        CURRENT_STATE: fromPage = 'organization',
      },
    } = storage

    const uri = `/login/${provider}`
    const bottomContent = [] as any[]
    return (
      <div className="form-container">
        <span className="header">{this.props.label}</span>
        <form className="form" onSubmit={this.props.onSubmit}>
          <div className="main-content">
            {_.map(this.props.content, (key: any) => {
              if (key.type === 'checkbox' && (key.cText || key.pText)) {
                // push the checkboxes to bottom
                bottomContent.push(key)
                return null
              }
              return (
                <div className="form-group" key={key.id}>
                  {key.label ? (
                    <span className="label">{key.label}</span>
                  ) : null}
                  <input
                    type={key.type}
                    value={key.value}
                    placeholder={key.placeholder}
                    required={key.required}
                    onChange={e => this.props.onChange(e)}
                    className="form-control"
                    disabled={key.disabled}
                    name={key.name}
                    onKeyPress={e => this.keyPressFun(e, key.passCheck)}
                  />
                </div>
              )
            })}
          </div>

          {bottomContent.length > 0 && (
            <div className="bottom-content">
              {_.map(bottomContent, (key: any) => {
                return (
                  <div className="form-group" key={key.id}>
                    <input
                      type={key.type}
                      value={key.value}
                      placeholder={key.placeholder}
                      required={key.required}
                      onChange={e => this.props.onChange(e)}
                      className="form-control"
                      disabled={key.disabled}
                      name={key.name}
                      onKeyPress={e => this.keyPressFun(e, key.passCheck)}
                    />
                    {key.pText ? (
                      <span className="condition">
                        I agree to the Provider's{' '}
                        <span
                          className="navigate-link"
                          onClick={() => this.setEulaActivation(true)}
                        >
                          Agreements.
                        </span>
                      </span>
                    ) : null}
                    {key.cText ? (
                      <span className="condition">
                        I agree to the A10{' '}
                        <a
                          className="navigate-link"
                          href="https://www.a10networks.com/company/legal-notices/a10-networks-website-terms-useservice"
                          target="_blank"
                        >
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                          className="navigate-link"
                          href="https://www.a10networks.com/company/legal-notices/a10-networks-privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy.
                        </a>
                      </span>
                    ) : null}
                  </div>
                )
              })}
            </div>
          )}

          <div className="main-content">
            <div className="form-group">
              <input
                type="button"
                className="btn login-btn"
                id="loginBtn"
                value={this.props.submitLabel}
                onClick={this.props.onSubmit}
                required={true}
              />
            </div>
            <div className="action-links">
              {this.props.showBack ? (
                <a href={'/controller/' + fromPage} className="action-link">
                  Back
                </a>
              ) : null}
              {this.props.showForgotPass ? (
                <a href="/resetPassword" className="action-link">
                  Forgot password?
                </a>
              ) : null}
              {this.props.showCancel ? (
                <a href={uri} className="action-link">
                  Cancel
                </a>
              ) : null}
            </div>
          </div>
        </form>
        <EulaActivation
          show={showEulaActivation}
          setVisible={this.setEulaActivation}
          user={this.props.user}
          token={this.props.token}
          provider={provider}
        />
      </div>
    )
  }
}
export default NonLoggenInForm
