import React, { useState, useEffect, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import {
  A10Icon,
  A10Steps,
  A10Input,
  A10Button,
  A10Message,
} from '@gui-libraries/widgets'
import { AuthenticationService } from 'src/services'
import Footer from '../../Footer'
import styles from '../Styles/index.module.less'
import SecondFactorAuthentication from '../MFATwoFactorAuthentication/SecondFactorAuthentication'
import { MILI_SECONDS } from 'src/constants/MFAStatus/MFAConstants'

export const { Step } = A10Steps
export const authenticationService = new AuthenticationService()

export const getFormattedDate = () => {
  const sCurrentDate = new Date()
  const sDate = String(sCurrentDate.getDate()).padStart(2, '0')
  const sMonth = String(sCurrentDate.getMonth() + 1).padStart(2, '0')
  const sYear = sCurrentDate.getFullYear()
  return `${sMonth}'/'${sDate}'/'${sYear}`
}

const MFARegistration = (props: any) => {
  const [setupLater, setSetupLater] = useState(false)
  const [verifyAndContinue, setVerifyAndContinue] = useState(false)
  const [showKey, setShowkey] = useState(false)
  const [renderQRCode, setRenderQRCode] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [key, setkey] = useState('')
  const [validateUserInputField, setValidateUserInputField] = useState('')
  const [numberOfDaysRemaining, setNumberOfDaysRemaining] = useState(0)

  useEffect(() => {
    async function fetchQRCode() {
      const payload = {
        userId: props.authenticationHeader.payload.userId,
      }

      const headers = {
        Authorization: props.authenticationHeader.headers.Authorization,
        provider: props.userDetails.provider,
      }
      const qrCode = await authenticationService.createQRCOde(
        headers,
        payload,
        null,
      )
      setRenderQRCode(qrCode.data.img)
      setkey(qrCode.data.secret)
      setIsLoading(false)
    }
    fetchQRCode()
  }, [])

  useEffect(() => {
    const currentDate = new Date(getFormattedDate())
    const enforcedDate = new Date(props.enforcedDate)
    const differenceInDays =
      Math.round((enforcedDate.getTime() - currentDate.getTime()) / (MILI_SECONDS))

    if (!isNaN(differenceInDays) && differenceInDays > 0) {
      setNumberOfDaysRemaining(differenceInDays)
    }
  }, [])

  const onSetupLaterClicked = useCallback(() => {
    props.setupLater()
    setSetupLater(true)
  }, [])

  const handleShowKey = useCallback(() => {
    setShowkey(!showKey)
  }, [showKey])

  const handleVerifyAndContinue = useCallback(() => {
    if (validateUserInputField.length == 6) {
      const payload = {
        userId: props.authenticationHeader.payload.userId,
        totp: validateUserInputField,
      }

      const headers = {
        Authorization: props.authenticationHeader.headers.Authorization,
        provider: props.userDetails.provider,
      }

      const validateOTP = authenticationService.validateOtp(
        headers,
        payload,
        null,
      )

      validateOTP
        .then(otpResponse => {
          const { status: statusCode } = otpResponse
          if (statusCode === 200) {
            A10Message.success('Successfully validated one-time password')
            setVerifyAndContinue(true)
          } else {
            A10Message.error('Failed to validate one-time password')
          }
        })
        .catch((error: any) => {
          setVerifyAndContinue(false)
          A10Message.error(
            'An error occurred while validating one-time password',
          )
        })
    } else {
      A10Message.error('Please enter valid Time-based One-Time Passwords(TOTP)')
    }
  }, [validateUserInputField])

  const handleValidateInputFieldChange = useCallback((event: any) => {
    setValidateUserInputField(event.target.value)
  }, [])

  const handleRecovery = useCallback(() => {
    props.recoverMFA(
      props.authenticationHeader.payload.userId,
      props.authenticationHeader.headers.Authorization
    )
  }, [])

  const strURL = `${props.appToBeLoaded}`
  return verifyAndContinue ? (
    <SecondFactorAuthentication
      userDetails={props.userDetails}
      sessionResponse={props.sessionResponse}
      userResponse={props.userResponse}
      authenticationHeader={props.authenticationHeader}
      recoverMFA={handleRecovery}
      appToBeLoaded={strURL}
      isProviderAdmin={props.isProviderAdmin}
      setLocalStorage={props.setLocalStorage}
    />
  ) : (
    <div className={styles.registrationDefault}>
      <div className={styles.registrationView}>
        <div className={styles.productLogContainer}>
          <A10Icon app="app-icons" type="harmony-controller-light" />
          <div className={styles.productName}>Harmony Controller</div>
        </div>
        <div className={styles.header}>Multifactor Authentication Setup</div>
        <div className={styles.mfaSubtext}>
          Your account is now protected by MFA. Please follow the steps to
          configure MFA for your account access.
        </div>
        <div className={styles.stepsContainer}>
          <A10Steps direction="vertical">
            <Step
              style={{ marginTop: '20px' }}
              className={styles.mfaStepsDesc}
              title={
                <span className={styles.mfaSubtext}>
                  Install or use an existing authentication app
                </span>
              }
              description=""
            />
            <Step
              title={
                <span className={styles.mfaSubtext}>
                  In the app choose to add an account and scan the following QR
                  code :
                </span>
              }
              description={
                <div className={styles.mfaSubtext}>
                  <div className={styles.mfaStepDescription}>
                    <div className={styles.mfaStepsDescSubText}>
                      {!isLoading && (
                        <img
                          style={{ width: '100%' }}
                          src={`data:image/jpg;base64,${renderQRCode}`}
                        />
                      )}
                    </div>
                    <div className={styles.cantScan}>
                      If you can't scan , you can manually enter this below key
                      into your authentication app.{' '}
                      <div
                        className={styles.showHideKey}
                        onClick={handleShowKey}
                      >
                        {showKey ? 'Hide Key' : 'Show Key'}
                      </div>
                      {showKey && <A10Input size="small" value={key} />}
                    </div>
                  </div>
                </div>
              }
            />

            <Step
              title={
                <span className={styles.mfaSubtext}>
                  Please enter a time based One-Time Password <span className={styles.mfaTotpNote}>(6-digit code displayed in your authenticator app)</span>:
                </span>
              }
              description=" "
            />
            <div className={styles.inputeField}>
              <A10Input
                size="small"
                placeholder="Enter code"
                onChange={handleValidateInputFieldChange}
              />
            </div>
            <div className={styles.verifyBtn}>
              <A10Button type="primary" onClick={handleVerifyAndContinue}>
                Verify and Continue
              </A10Button>
            </div>
            <div className={styles.setupLater}>
              <a
                href="javascripot:;"
                onClick={onSetupLaterClicked}
                className={numberOfDaysRemaining <= 0 && styles.disableSetupLater}
              >
                Setup later
              </a>
              {` Enforcement Date is ${props.enforcedDate}, ${numberOfDaysRemaining} ${numberOfDaysRemaining === 1 ? `day` : `days`}  remaining`}
            </div>
          </A10Steps>
        </div>
        {setupLater && <Redirect to={strURL} />}

        {verifyAndContinue && (
          <SecondFactorAuthentication
            userDetails={props.userDetails}
            appToBeLoaded={strURL}
            recoverMFA={handleRecovery}
            isProviderAdmin={props.isProviderAdmin}
          />
        )}

        <div style={{ marginLeft: '-20px' }}>
          <Footer pageType="login" />
        </div>
      </div>
    </div>
  )
}
export default MFARegistration
