import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Icon } from '@gui-libraries/widgets'
import styles from './styles/index.module.less'

export interface IProductLogoProps {}
export interface IProductLogoState {}

class ProductLogo extends A10Component<IProductLogoProps, IProductLogoState> {
  render() {
    return (
      <div className={styles.productLogContainer}>
        <A10Icon app="app-icons" type="harmony-controller-light" />
        <div className={styles.productName}>Harmony Controller</div>
      </div>
    )
  }
}
export default ProductLogo
