import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { FormHeader } from 'src/components/shared/FormHeader'
import { Utilities } from 'src/services/index'
import './styles/googleauthlogin.scss'

export interface IGoogleAuthLoginProps {
  label: string
  // onSubmit: any
  submitLabel: any
  oauthClientID: string
  setPayload: (payload: any) => void
}

export interface IGoogleAuthLoginState {}

class GoogleAuthLogin extends A10Component<
  IGoogleAuthLoginProps,
  IGoogleAuthLoginState
> {
  Utilities = new Utilities()

  loginWithGoogle = () => {
    const clientID = this.props.oauthClientID
    const inheritable = 'optional'

    // load in the auth2 api's, without it gapi.auth2 will be undefined
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: clientID,
      })

      // get's a GoogleAuth instance with your client-id, needs to be called after gapi.auth2.init
      const GoogleAuth = window.gapi.auth2.getAuthInstance()

      GoogleAuth.signIn().then((response: any) => {
        // request to sign in

        // to get the access token
        const keys = response && Object.keys(response)
        let accessToken = ''
        keys &&
          keys.map((key: string) => {
            if (accessToken === '' && response[key].access_token) {
              accessToken = response[key].access_token
            }
          })

        const payload = {
          inheritable,
          type: 'oauth',
          scheme: 'gcp',
          clientid: clientID,
          access_token: accessToken,
        }

        this.props.setPayload(payload)
        // this.props.onSubmit()
      })
    })
  }

  render() {
    const authFallbackLink =
      window.location.origin +
      window.location.pathname +
      '?google_auth_fallback=1'
    return (
      <div className="col-md-12 col-sm-12 google-login-container">
        <FormHeader label={this.props.label} />
        <div className="col-md-12 col-sm-12 centered login-form">
          <div className="form-group clearfix">
            <button onClick={this.loginWithGoogle} className="google-login" />
          </div>
        </div>
        <a href={authFallbackLink} className="admin-link">
          Admin Login
        </a>
      </div>
    )
  }
}
export default GoogleAuthLogin
