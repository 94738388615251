import React, { useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import {
  A10Button,
  A10Input,
  A10Icon,
  A10Message,
} from '@gui-libraries/widgets'
import { AuthenticationService } from 'src/services/index'
import { Footer } from 'src/components/shared/Footer'
import { UserRole } from 'src/constants/Data/UserRole'
import 'src/styles/login.less'
import styles from '../Styles/index.module.less'

const SecondFactorAuthentication = (props: any) => {
  const userRoles = new UserRole()
  const authenticationService = new AuthenticationService()
  const [userInput, setUserInput] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)

  const handleLoginClicked = useCallback(() => {
    if (userInput.length === 6) {
      const payload = {
        userId: props.authenticationHeader.payload.userId,
        totp: userInput,
      }

      const headers = {
        Authorization: props.authenticationHeader.headers.Authorization,
        provider: props.userDetails.provider,
      }

      const validateOTP = authenticationService.validateOtp(
        headers,
        payload,
        null,
      )
      validateOTP
        .then((otpResponse: IObject) => {
          const { status: statusCode } = otpResponse
          if (statusCode === 200) {
            props.setLocalStorage(props.sessionResponse, props.userResponse)
            A10Message.success('Successfully validated one-time password')
            setLoggedIn(true)
          } else {
            A10Message.error('Failed to validate one-time password')
          }
        })
        .catch(error => {
          A10Message.error(
            'An error occurred while validating one-time password',
          )
        })
    } else {
      A10Message.error('Please enter valid Time-based One-Time Passwords(TOTP)')
    }
  }, [props, userRoles])

  const handleInputChange = useCallback(e => {
    setUserInput(e.target.value)
  }, [])

  const handleRecovery = useCallback(() => {
    props.recoverMFA(
      props.authenticationHeader.payload.userId,
      props.authenticationHeader.headers.Authorization,
    )
  }, [])

  return !loggedIn ? (
    <div className={styles.validationOtpDefault}>
      <div className="a10-logo-container">
        <div className="a10-logo" />
      </div>
      <div className={styles.registrationView}>
        <div className={styles.productLogContainer}>
          <A10Icon app="app-icons" type="harmony-controller-light" />
          <div className={styles.productName}>Harmony Controller</div>
        </div>
        <div className={styles.headerTwofa}>
          Please enter a time based One-Time Password
          <span className={styles.mfaTotpNote}> (6-digit code displayed in your authenticator app)</span>
        </div>

        <div className={styles.inputeFieldTwoFa}>
          <div className={styles.otp}>OTP</div>
          <A10Input
            onChange={handleInputChange}
            size="small"
            placeholder="Enter code"
            style={{ width: '155px', height: '31px' }}
          />
        </div>
        <div className={styles.loginTwoFa}>
          <A10Button
            type="primary"
            className={styles.loginTwoFa}
            onClick={handleLoginClicked}
            style={{ width: '155px' }}
          >
            Submit
          </A10Button>
        </div>
        {props.isProviderAdmin && (
          <div className={styles.haveTrouble} onClick={handleRecovery}>
            Have trouble with MFA? Email MFA reset instructions.
          </div>
        )}
        <div style={{ marginLeft: '-20px' }}>
          <Footer pageType="login" />
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={`${props.appToBeLoaded}`} />
  )
}

export default SecondFactorAuthentication
